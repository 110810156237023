import React, {useEffect, useMemo, useRef, useState} from 'react';
import axios from "axios";
import Order from "./order";
import {AdvancedRealTimeChart} from "react-ts-tradingview-widgets-fixed";
import Loading from "../loading/loading";
import OrdersTable from "./ordersTable";
import "../verstka(temp)/css/style.css"
import '../orderTable.css'
import {NavLink} from "react-router-dom";
import PairsList from "../pairsList";
import CloseIcon from "@mui/icons-material/Close";
import {closeSnackbar, useSnackbar} from "notistack";
import posts1 from "../account/img/posts1.svg";
import posts2 from "../account/img/posts2.svg";
import "./frame.css"
import {PriceChart} from "./chart/priceChart";
import {useTranslation} from "react-i18next";
const MarketStocks = () => {
    const {t} = useTranslation()
    const iframeRef = useRef(null);




    useEffect(() => {
        const handleMessage = (event) => {
          if (event.origin !== 'https://widget.darqube.com') return;

          const styles = event.data?.styles;
          if (styles) {
            Object.keys(styles).forEach(key => iframeRef.current.style.setProperty(key, styles[key]))
          }};

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

    const { enqueueSnackbar } = useSnackbar();
    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );
    (function() {
        function decimalAdjust(type, value, exp) {
            if (typeof exp === 'undefined' || +exp === 0) {
                return Math[type](value);
            }
            value = +value;
            exp = +exp;
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                return NaN;
            }
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }
        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
                return decimalAdjust('floor', value, exp);
            };
        }
    })();
    function componentDidMount () {
        const script = document.createElement("script");

        script.src = "/verstka(temp)/js/app.min.js";
        script.async = true;

        document.body.appendChild(script);
    }
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    const token = localStorage.getItem('token');
    const url = new URL(document.location.href);
    let symbolMarket = (url.searchParams.get('symbol'));
    let frameLink = (url.searchParams.get('frame'));

    let match = symbolMarket.match(/(\w+)(USDT|RUB)/);

    // данные о пользователе
    const [userBalance, setUserBalance] = useState("");
    const [userBalanceSecond, setUserBalanceSecond] = useState("");
    const [userOrdersClose, setUserOrdersClose] = useState([]);
    const [userOrdersOpen, setUserOrdersOpen] = useState([]);
    const [userOrdersWaiting, setUserOrdersWaiting] = useState([]);
    const [userOrdersOpenTotal, setUserOrdersOpenTotal] = useState([]);
    const [userOrdersClosedTotal, setUserOrdersClosedTotal] = useState([]);
    const [userOrdersWaitingTotal, setUserOrdersWaitingTotal] = useState([]);

    // загрузчики
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedMT, setIsLoadedMT] = useState(false);
    const [isLoadedOrders, setIsLoadedOrders] = useState(false);
    const [isLoadedOrdersTable, setIsLoadedOrdersTable] = useState(false);

    // ордера
    const [orders, setOrders] = useState([]);
    const [ordersAsks, setOrdersAsks] = useState([]);

    // настройки нового ордера
    const [leverage, setLeverage] = useState([3, 10, 20, 50, 100])
    const [leverageSecond, setLeverageSecond] = useState(3)
    const [price, setPrice] = useState("")
    const [priceSell, setPriceSell] = useState("")
    const [amount, setAmount] = useState("")
    const [amountSell, setAmountSell] = useState("")
    const [price2, setPrice2] = useState("")
    const [amount2, setAmount2] = useState("")
    const [priceSell2, setPriceSell2] = useState("")
    const [amountSell2, setAmountSell2] = useState("")
    const [action2, setAction] = useState("buy");
    const [actionSell, setActionSell] = useState("sell");
    const [marketValue, setMarketValue] = useState()
    const [marketValueSell, setMarketValueSell] = useState()

    const [takeProfitLong, setTakeProfitLong] = useState(0)
    const [stopLossLong, setStopLossLong] = useState(0)
    const [takeProfitShort, setTakeProfitShort] = useState(0)
    const [stopLossShort, setStopLossShort] = useState(0)

    const [takeProfitLongMarket, setTakeProfitLongMarket] = useState(0)
    const [stopLossLongMarket, setStopLossLongMarket] = useState(0)
    const [takeProfitShortMarket, setTakeProfitShortMarket] = useState(0)
    const [stopLossShortMarket, setStopLossShortMarket] = useState(0)

    const [AVGdata, setAVGdata] = useState([]);



    const [total, setTotal] = useState("")
    const [totalSell, setTotalSell] = useState("")
    const [cointo, setcointo] = useState(match[1]);
    const [coinfrom, setCoinFrom] = useState(match[2]);

    const leverageArray = leverage.map(x => x)
    const handleLeverageChange = async (e) => {

        setLeverageSecond((leverage[e.target.value]))
    }
    const handleStopLossShort = (e) => {
        setStopLossShort(e.target.value);
    };
    const handleStopLossLong = (e) => {
        setStopLossLong(e.target.value);
    };
    const handleTakeProfitShort = (e) => {
        setTakeProfitShort(e.target.value);
    };
    const handleTakeProfitLong = (e) => {
        setTakeProfitLong(e.target.value);
    };
    const handleStopLossShortMarket = (e) => {
        setStopLossShortMarket(e.target.value);
    };
    const handleStopLossLongMarket = (e) => {
        setStopLossLongMarket(e.target.value);
    };
    const handleTakeProfitShortMarket = (e) => {
        setTakeProfitShortMarket(e.target.value);
    };
    const handleTakeProfitLongMarket = (e) => {
        setTakeProfitLongMarket(e.target.value);
    };
    const requireAVGdata = () => axios.get(`https://api.dune-ex.com/api/api/share/get_avg?symbol=${match[1]}`)
        .then(res => {
            setAVGdata(res.data);
            setTimeout(() => requireAVGdata(), 20000)
        })
    let BalanceID = symbolMarket.split('RUB')[0].toLowerCase()
    const requireUserBalancePR = () => {
        requireUserBalance();
        requireUserBalanceSecond()
    }
    const requireUserBalance = () => axios.get(
        `https://api.dune-ex.com/api/api/users/balance/invest`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setUserBalance(res.data.rub);
        });
    const requireUserBalanceSecond = () => axios.get(
        `https://api.dune-ex.com/api/api/users/balance/invest`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            match[2] === "USDT" ?
                setUserBalanceSecond( Math.floor10(res.data[`${match[2].toLowerCase()}`], -2) )
                : setUserBalanceSecond( Math.floor10(res.data[`${match[2].toLowerCase()}`], -7) )
        });

    const [page1, setPage1] = useState(1)
    const [page2, setPage2] = useState(1)
    const [page3, setPage3] = useState(1)

    const requireUserDataClosed = () => axios.get(
        `https://api.dune-ex.com/api/api/share/close?size=5&page=${page2}`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setUserOrdersClose(res.data.items);
            setUserOrdersClosedTotal(res.data.total);
        })
    const requireUserDataWaiting = () => axios.get(
        `https://api.dune-ex.com/api/api/share/waiting?size=5&page=${page3}`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setUserOrdersWaiting(res.data.items);
            setUserOrdersWaitingTotal(res.data.total);
            setIsLoadedOrdersTable(true)
        })

    const requireUserDataOpen = () => {
        axios.get(
            `https://api.dune-ex.com/api/api/share/open?size=5&page=${page1}`,
            {
                headers: {"Authorization" : `Bearer ${token}`}
            })
            .then(res => {
                setUserOrdersOpen(res.data.items);
                setUserOrdersOpenTotal(res.data.total);
            });
    }
    const requireUserDataOpen1 = () => axios.get(
        `https://api.dune-ex.com/api/api/share/open?size=5&page=${page1}`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setUserOrdersOpen(res.data.items);
            setUserOrdersOpenTotal(res.data.total);
        })

    const requireUserDataClosed1 = () => {
        axios.get(
            `https://api.dune-ex.com/api/api/share/close?size=5&page=${page2}`,
            {
                headers: {"Authorization": `Bearer ${token}`}
            })
            .then(res => {
                setUserOrdersClose(res.data.items);
                setUserOrdersClosedTotal(res.data.total);
            });
    }

    const requireUserDataWaiting1 = () => axios.get(
        `https://api.dune-ex.com/api/api/share/waiting?size=5&page=${page3}`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setUserOrdersWaiting(res.data.items);
            setUserOrdersWaitingTotal(res.data.total);
            setIsLoadedOrdersTable(true)
        })

    useEffect(() => {
        requireUserDataOpen()
        requireUserDataClosed()
        requireUserDataWaiting()
    }, [])



    const [counter, setCounter] = useState(0)

    const upcount = () => {
        setCounter(Math.random)
        setTimeout(upcount, 3000)
    }
    useEffect(() => {
        requireUserDataOpen1(page1)
    }, [counter])
    useEffect(() => {
        upcount()
    }, [])

    const newPageHandler = (pg) => {
        setPage1(pg);
    }
    useEffect(() => {
        requireUserDataOpen1()
    }, [page1])





    const [counter2, setCounter2] = useState(0)
    const upcount2 = () => {
        setCounter2(Math.random)
        setTimeout(upcount2, 3000)
    }
    useEffect(() => {
        requireUserDataClosed1()
    }, [counter2])
    useEffect(() => {
        upcount2()
    }, [])
    const newPageHandler2 = (pg) => {
        setPage2(pg);
    }
    useEffect(() => {
        requireUserDataClosed1()
    }, [page2])





    const [counter3, setCounter3] = useState(0)
    const upcount3 = () => {
        setCounter3(Math.random)
        setTimeout(upcount3, 3000)
    }
    useEffect(() => {
        requireUserDataWaiting1()
    }, [counter3])
    useEffect(() => {
        upcount3()
    }, [])



    const newPageHandler3 = (pg) => {
        setPage3(pg);
    }

    useEffect(() => {
        requireUserDataWaiting1()
    }, [page3])

    let chartContent = useMemo(() => <AdvancedRealTimeChart  timezone="Europe/Moscow" theme="dark" allow_symbol_change="false" interval={"5"} height={50} hide_top_toolbar={true} symbol="BTCUSDT" autosize />, []);

    const priceHandler = (e) => {
        setPrice(e.target.value);
        setPrice2(e.target.value);
        setTotal(Math.floor10(amount * e.target.value, -6))
    };
    const amountHandler = (e) => {
        setAmount(e.target.value);
        setAmount2(e.target.value);
        setTotal(Math.floor10(e.target.value * price, -6))
    };
    const totalHandler = (e) => {
        setTotal(e.target.value);
        setAmount(Math.floor10(e.target.value / price, -6));
    };
    const totalSellHandler = (e) => {
        setTotalSell(e.target.value);
        setAmountSell(Math.floor10(e.target.value / price, -6));
    };

    const priceSellHandler = (e) => {
        setPriceSell(e.target.value);
        setPriceSell2(e.target.value);
        setTotalSell(Math.floor10(e.target.value * amountSell, -6))
    };
    const amountSellHandler = (e) => {
        setAmountSell(e.target.value);
        setAmountSell2(e.target.value);
        setTotalSell(Math.floor10(priceSell * e.target.value, -6))
    };

    const handleSubmitOrderLong = (event) => {
        event.preventDefault();
        axios({
            method: "post",
            url: "https://api.dune-ex.com/api/api/share",
            data: {"margin": AVGdata.lastPrice * amount, total, price, amount, "action": action2, "name": cointo, "coin": coinfrom, "leverage": leverageSecond, "take_profit": takeProfitLong, "stop_loss": stopLossLong, "short_long": true, "is_limited" : true, "limit": orders[0][0]},
            headers: { "Authorization" : `Bearer ${token}` },
        })
            .then(res => {
                setPrice("")
                setAmount("")
                requireUserDataOpen();
                requireUserDataWaiting()
                requireUserBalanceSecond();
                requireUserBalance();
                enqueueSnackbar(`Ордер создан!`, {action, 'variant': 'success', 'className': "snacksuccess"});
            }).catch( error => {
            error.response.data.detail.map(
                errormsg => {
                    enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                }
            )


        });
    };
    const handleSubmitOrderShort = (event) => {
        let total = totalSell
        let price = priceSell
        let amount = amountSell
        event.preventDefault();
        axios({
            method: "post",
            url: "https://api.dune-ex.com/api/api/share",
            data: {"margin": AVGdata.lastPrice * amount, total, price, amount, "action": actionSell, "name": cointo, "coin": coinfrom, "leverage": leverageSecond, "take_profit": takeProfitShort, "stop_loss": stopLossShort, "short_long": false, "is_limited" : true, "limit": ordersAsks[0][0]},
            headers: { "Authorization" : `Bearer ${token}` },
        })
            .then(res => {
                enqueueSnackbar(`Ордер создан!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                requireUserDataOpen();
                requireUserDataWaiting()
                requireUserBalanceSecond();
                requireUserBalance();
                setAmountSell("")
                setPriceSell("")
            }).catch( error => {

            error.response.data.detail.map(
                errormsg => {
                    enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                }
            )
        });
    };
    const handleSubmitOrderLongMT = (event) => {
        event.preventDefault();
        axios({
            method: "post",
            url: "https://api.dune-ex.com/api/api/share",
            data: {"margin": AVGdata.lastPrice * amount, total, "price": AVGdata.lastPrice, amount, "action": action2, "name": cointo, "coin": coinfrom, "leverage": leverageSecond, "take_profit": takeProfitLongMarket, "stop_loss": stopLossLongMarket, "short_long": true, "is_limited" : false},
            headers: { "Authorization" : `Bearer ${token}` },
        })
            .then(res => {
                setAmount("")
                requireUserDataOpen();
                requireUserDataWaiting()
                requireUserBalanceSecond();
                requireUserBalance();
                enqueueSnackbar(`Ордер создан!`, {action, 'variant': 'success', 'className': "snacksuccess"});
            }).catch( error => {
            error.response.data.detail.map(
                errormsg => {
                    enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                }
            )


        });
    };
    const handleSubmitOrderShortMT = (event) => {
        let total = totalSell
        let amount = amountSell
        event.preventDefault();
        axios({
            method: "post",
            url: "https://api.dune-ex.com/api/api/share",
            data: {"margin": AVGdata.lastPrice * amount, total, "price": AVGdata.lastPrice, amount, "action": actionSell, "name": cointo, "coin": coinfrom, "leverage": leverageSecond, "take_profit": takeProfitShortMarket, "stop_loss": stopLossShortMarket, "short_long": false, "is_limited" : false},
            headers: { "Authorization" : `Bearer ${token}` },
        })
            .then(res => {
                enqueueSnackbar(`Ордер создан!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                requireUserDataOpen();
                requireUserDataWaiting()
                requireUserBalanceSecond();
                requireUserBalance();
                setAmountSell("")
            }).catch( error => {

            error.response.data.detail.map(
                errormsg => {
                    enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                }
            )
        });
    };

    const setPropsPrice = (data) =>{
        setPrice(data)
    }
    const setPropsPriceSell = (data) =>{
        setPriceSell(data)
    }

    let symbolDivided = `${match[1]}/${match[2]}`


    const [limit, setLimit] = useState(true)
    const [market, setMarket] = useState(false)
    const limitSwitch = () => {
        limit
            ?(() => {
                setLimit(false)
                setMarket(true) })()

            :
            (() => {
                setLimit(true)
                setMarket(false) })()
    }
    const marketSwitch = () => {
        market
            ?(() => {
                setLimit(true)
                setMarket(false) })()

            :
            (() => {

                setLimit(false)
                setMarket(true)
                setPriceSell(ordersAsks[0][0])
                setPrice(ordersAsks[0][0])
                setPriceSell2(ordersAsks[0][0])
                setPrice2(ordersAsks[0][0])
            })()
    }
        const handleOrderBook = () => {
        let stockName = match[1]
        axios.get(`https://api.dune-ex.com/api/api/share/get_orderbook?name=${stockName}&limit=15`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
            .then(res => {
                setOrdersAsks(res.data.data.asks);
                setOrders(res.data.data.bids);
                setPrice(res.data.data.asks[0][0])
                setPriceSell(res.data.data.bids[0][0])
                setPrice2(res.data.data.asks[0][0])
                setPriceSell2(res.data.data.bids[0][0])
                setIsLoadedOrders(true);
            })
    }

        const handleGetOrders = () => {
        let stockName = match[1]

        axios.get(`https://api.dune-ex.com/api/api/share/get_orderbook?name=${stockName}&limit=15`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
            .then(res => {
                setOrdersAsks(res.data.data.asks);
                setOrders(res.data.data.bids);
                setTimeout( () => handleGetOrders(), 7000)
            })
    }

    useEffect(() => {
        requireAVGdata();
        requireUserBalance();
        requireUserBalanceSecond();
        handleGetOrders();
        handleOrderBook();
    }, []);
    const [x, setX] = useState(false);
    const [historyOrders, setHistoryOrders] = useState(1);
    const [elems, setElems] = useState([])
    const [cache, setCache] = useState({});
    useEffect(() => {
        requireUserBalance();
        requireUserBalanceSecond();
        Promise.all(
            userOrdersOpen.map(async (ordersTable) => {
                const coinPair = `${ordersTable.name}${ordersTable.coin.toUpperCase()}`;

                if (coinPair in cache) {
                    const cr = cache[coinPair]
                    const isLong = ordersTable.short_long;
                    const leverage = ordersTable.leverage;
                    const amount = ordersTable.amount;
                    const price = ordersTable.price;

                    const size = cr * amount * leverage;
                    const margin = cr * amount;

                    const result = (
                        <OrdersTable
                            pnl={ordersTable.pnl}
                            pnlPercent={ordersTable.pnl_percent}
                            size={size}
                            margin={margin}
                            tpslEdit={true}
                            closeButton={true}
                            isLong={isLong}
                            avg={cr}
                            date={ordersTable.time}
                            price={price}
                            coin={ordersTable.name}
                            coinfrom={ordersTable.coin}
                            amount={amount}
                            filled={ordersTable.filled}
                            total={ordersTable.total}
                            action={ordersTable.action}
                            id={ordersTable.id}
                            leverage={leverage}
                            takeProfit={ordersTable.take_profit}
                            stopLoss={ordersTable.stop_loss}
                            requireUserData={requireUserDataOpen}
                            requireUserBalance={requireUserBalancePR}
                        />
                    );
                    return result;
                }

                try {
                    const res = await axios.get(`https://api.dune-ex.com/api/api/share/get_avg?symbol=${ordersTable.name}`);

                    const cacheResult = res.data.lastPrice
                    const lastPrice = res.data.lastPrice;
                    const leverage = ordersTable.leverage;
                    const amount = ordersTable.amount;
                    const price = ordersTable.price;
                    const isLong = ordersTable.short_long;

                    const size = lastPrice * amount * leverage;
                    const margin = lastPrice * amount;


                    const margin2 = lastPrice * amount;

                    const result = (
                        <OrdersTable
                            pnl={ordersTable.pnl}
                            pnlPercent={ordersTable.pnl_percent}
                            size={size}
                            margin={margin}
                            tpslEdit={true}
                            margin2={margin2}
                            closeButton={true}
                            isLong={isLong}
                            avg={lastPrice}
                            date={ordersTable.time}
                            price={price}
                            coin={ordersTable.name}
                            coinfrom={ordersTable.coin}
                            amount={amount}
                            filled={ordersTable.filled}
                            total={ordersTable.total}
                            action={ordersTable.action}
                            id={ordersTable.id}
                            leverage={leverage}
                            takeProfit={ordersTable.take_profit}
                            stopLoss={ordersTable.stop_loss}
                            requireUserData={requireUserDataOpen}
                            requireUserBalance={requireUserBalancePR}
                        />
                    );

                    // Сохраняем данные в кеш
                    setCache({ ...cache, [coinPair]: cacheResult });

                    return result;
                } catch (error) {
                    console.warn(`Ошибка при запросе для ${coinPair}: ${error}`);
                    return null;
                }
            })
        ).then((result) => {
            setElems(result.filter((elem) => elem !== null));
            setCache({})
        });
    }, [userOrdersOpen]);


    const [hdnpp, setHdnpp] = useState(true)
    const [ava, setAva] = useState({});
    const isOrdersAvilable = () => {
        axios.get("https://api.dune-ex.com/api/api/users/shares/available", {
            headers: {"Authorization" : `Bearer ${token}`}
        })
            .then(res => {
                if (res.data == false){
                    setHdnpp(false)
                }
            })
    }
    useEffect(() => {
        isOrdersAvilable()
    }, [])

    return (
        <>

            <div className={`deposit-popup-window  ${hdnpp ? "hidden" : ""}`} >
                <div className="dep-popup1234 dangobt">
                    <div className="rdfngrjdbfjk" onClick={() => setHdnpp(true)}>X</div>
                </div>
                <div className="deposit-popup123 dangob" >

                    <div className="centeryo">
                        <p className="kyc__name">
                            Все операции по вашему счету временно приостановлены, обратитесь в службу поддержки
                        </p>
                    </div>

                </div>

            </div>
            <div className="wrapper">
                <main className="page">
                    <section className="bin">
                        <div className="bin__container">
                            <div className="bin__body">
                                <div className="bin__left">
                                    <div className="bin__top">
                                        <div className="bin__para">
                <span>
                  {symbolDivided}
                </span>
                                        </div>
                                        {/*<div className="bin__two">*/}
                                        {/*    <div className={`bin__t-top ${colorChange(marketInfo.priceChangePercent)}`}>{Math.floor10(marketInfo.lastPrice, -3) }</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="bin__t-item">*/}
                                        {/*    <div className="bin__t-verh">24h Change</div>*/}
                                        {/*    <div className="bin__t-niz">*/}
                                        {/*        <span className={`${colorChange(marketInfo.priceChangePercent)}`}>{marketInfo.priceChangePercent}%</span>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="bin__t-item">*/}
                                        {/*    <div className="bin__t-verh">24h High</div>*/}
                                        {/*    <div className="bin__t-niz">{Math.floor10(marketInfo.highPrice, -3)}</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="bin__t-item">*/}
                                        {/*    <div className="bin__t-verh">24h Low</div>*/}
                                        {/*    <div className="bin__t-niz">{Math.floor10(marketInfo.lowPrice, -3)}</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="bin__t-item">*/}
                                        {/*    <div className="bin__t-verh">24h Volume({match[1]})</div>*/}
                                        {/*    <div className="bin__t-niz">{Math.floor10(marketInfo.volume, -3)}</div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="bin__wrap">

                                        <PairsList />
                                        <div className="bin__w-chart">
                                            <div className="bin__w-grafik graph_stocks">
                                                {/*<iframe*/}
                                                {/*  ref={iframeRef}*/}
                                                {/*  src={`https://widget.darqube.com/advanced-chart-widget?token=660483037e7cd5981ec54d24`}*/}
                                                {/*/>*/}
                                                <PriceChart/>
                                            </div>
                                            <div className="bin__bottom">
                                                <div className="bin__b-top">
                                                    <div className="bin__b-para">{symbolDivided}</div>
                                                </div>
                                                <div className="bin__b-body">
                                                    <div data-tabs="" className="tabs">
                                                        <div className="bin__b-navi">
                                                            <nav data-tabs-titles="" className="tabs__navigation">
                                                                <button
                                                                    onClick={limitSwitch}
                                                                    className={`tabs__title ${limit ? "_tab-active" : ""}`}
                                                                >
                                                                    {t("trade_buttons.limit")}
                                                                </button>
                                                                <button onClick={marketSwitch}
                                                                        className={`tabs__title ${market ? "_tab-active" : ""}`}>
                                                                    {t("trade_buttons.market")}
                                                                </button>
                                                                <div className="checkbox">
                                                                    <input
                                                                        id="TPSL"
                                                                        data-error="Error"
                                                                        className="checkbox__input"
                                                                        type="checkbox"
                                                                        checked={x} onChange={() => setX(!x)}
                                                                    />
                                                                    <label htmlFor="TPSL" className="checkbox__label">
                                                                        <span className="checkbox__text">TP/SL</span>
                                                                    </label>
                                                                </div>
                                                            </nav>
                                                            <div className="bin__b-inf">
                                                                <div className="select">
                                                                    <select onChange={e => handleLeverageChange(e)}
                                                                            className="form">
                                                                        {
                                                                            leverageArray.map((address, key) => <option
                                                                                key={key}
                                                                                value={key}>{address}x</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="znak">
                                                                    <img src="img/znak.svg?_v=1689845484602" alt=""/>
                                                                </div>
                                                                <NavLink to="/account/wallet">
                                                                    <div
                                                                        className="prevod">{t("trade_buttons.transfer")}</div>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                        <div data-tabs-body="" className="tabs__content">


                                                            <div className={`tabs__body ${limit ? "" : "hidden"}`}>
                                                                <div className="bin__b-box">
                                                                    <div className="polov">
                                                                        {isLoggedIn ? <div className="bin__b-dostup">
                                                                            <p className="mb0">{t("trade_buttons.available")}</p>
                                                                            <div className="bin__b-scr">
                                  <span className="bin__b-scet">
                                    {userBalanceSecond} {match[2]}
                                  </span>

                                                                            </div>
                                                                        </div> : ""}
                                                                        <form action="" className="bin__form">
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("right_menu.price")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="price-sell"
                                                                                        onChange={e => priceHandler(e)}
                                                                                        value={price}
                                                                                        defaultValue={AVGdata.lastPrice}
                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[2]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("wallet_page.amount")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="amount-sell"
                                                                                        defaultValue=""
                                                                                        value={amount}
                                                                                        onChange={e => amountHandler(e)}


                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[1]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("right_menu.total")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="amount-sell"
                                                                                        defaultValue=""
                                                                                        value={total}
                                                                                        onChange={e => totalHandler(e)}


                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[2]}
                                    </span>
                                                                                </label>
                                                                            </div>


                                                                            <input
                                                                                className="input-range-form green-icon"
                                                                                type="range"
                                                                                step={0.00001}
                                                                                onChange={(ev) => amountHandler(ev)}
                                                                                defaultValue={0}
                                                                                min="0" max={userBalanceSecond / price}
                                                                            />
                                                                            <div className={x ? "" : "tpsl"}>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.take_profit")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="price-sell"
                                                                                            value={takeProfitLong}
                                                                                            onChange={e => handleTakeProfitLong(e)}

                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.stop_loss")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="amount-sell"
                                                                                            value={stopLossLong}
                                                                                            onChange={e => handleStopLossLong(e)}

                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {isLoggedIn ?
                                                                                <button onClick={handleSubmitOrderLong}
                                                                                        className="button">
                                                                                    {t("trade_buttons.buy_long")}
                                                                                </button> : <NavLink to="/authorization"
                                                                                                     className="loginplease">{t("trade_buttons.log_in")}</NavLink>}
                                                                        </form>
                                                                    </div>
                                                                    <div className="polov polov2">
                                                                        {isLoggedIn ? <div className="bin__b-dostup">
                                                                            <p className="mb0">{t("trade_buttons.available")}</p>
                                                                            <div className="bin__b-scr">
                                  <span className="bin__b-scet">
                                    {userBalanceSecond} {match[2]}
                                  </span>

                                                                            </div>
                                                                        </div> : ""}
                                                                        <form action="" className="bin__form">
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("right_menu.price")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="price-sell"
                                                                                        onChange={e => priceSellHandler(e)}
                                                                                        value={priceSell}
                                                                                        defaultValue={AVGdata.lastPrice}
                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[2]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("wallet_page.amount")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="amount-sell"
                                                                                        onChange={e => amountSellHandler(e)}
                                                                                        value={amountSell}
                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[1]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("right_menu.total")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="amount-sell"
                                                                                        onChange={e => totalSellHandler(e)}
                                                                                        value={totalSell}
                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[2]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                className="input-range-form red-icon"
                                                                                type="range"
                                                                                step={0.00001}
                                                                                defaultValue={0}
                                                                                min="0"
                                                                                max={userBalanceSecond / priceSell}
                                                                                onChange={(ev) => amountSellHandler(ev)}
                                                                            />
                                                                            <div className={x ? "" : "tpsl"}>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.take_profit")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="price-sell"
                                                                                            value={takeProfitShort}
                                                                                            onChange={e => handleTakeProfitShort(e)}

                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.stop_loss")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="amount-sell"
                                                                                            value={stopLossShort}
                                                                                            onChange={e => handleStopLossShort(e)}

                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {isLoggedIn ?
                                                                                <button onClick={handleSubmitOrderShort}
                                                                                        className="button">
                                                                                    {t("trade_buttons.sell_short")}
                                                                                </button> : <NavLink to="/authorization"
                                                                                                     className="loginplease">{t("trade_buttons.log_in")}</NavLink>}
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className={`tabs__body ${market ? "" : "hidden"}`}>
                                                                <div className="bin__b-box">
                                                                    <div className="polov">
                                                                        {isLoggedIn ? <div className="bin__b-dostup">
                                                                            <p className="mb0">{t("trade_buttons.available")}</p>
                                                                            <div className="bin__b-scr">
                                  <span className="bin__b-scet">
                                    {userBalanceSecond} {match[2]}
                                  </span>

                                                                            </div>
                                                                        </div> : ""}
                                                                        <form action="" className="bin__form">
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("wallet_page.amount")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="price-sell"
                                                                                        value={amount}
                                                                                        onChange={e => amountHandler(e)}
                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                                                                        {match[1]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("right_menu.total")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="amount-sell"
                                                                                        defaultValue=""
                                                                                        value={total}
                                                                                        onChange={e => totalHandler(e)}


                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[2]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                className="input-range-form green-icon"
                                                                                type="range"
                                                                                step={0.00001}
                                                                                onChange={(ev) => amountHandler(ev)}
                                                                                defaultValue={0}
                                                                                min="0"
                                                                                max={userBalanceSecond / AVGdata.lastPrice}
                                                                            />
                                                                            <div className={x ? "" : "tpsl"}>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.take_profit")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="price-sell"
                                                                                            defaultValue="0"
                                                                                            value={takeProfitLongMarket}
                                                                                            onChange={e => handleTakeProfitLongMarket(e)}
                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.stop_loss")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="amount-sell"
                                                                                            defaultValue="0"

                                                                                            value={stopLossLongMarket}
                                                                                            onChange={e => handleStopLossLongMarket(e)}
                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {isLoggedIn ? <button
                                                                                onClick={handleSubmitOrderLongMT}
                                                                                className="button">
                                                                                Купить/Лонг
                                                                            </button> : <NavLink to="/authorization"
                                                                                                 className="loginplease">{t("trade_buttons.log_in")}</NavLink>}
                                                                        </form>
                                                                    </div>
                                                                    <div className="polov polov2">
                                                                        {isLoggedIn ? <div className="bin__b-dostup">
                                                                            <p className="mb0">{t("trade_buttons.available")}</p>
                                                                            <div className="bin__b-scr">
                                  <span className="bin__b-scet">
                                    {userBalanceSecond} {match[2]}
                                  </span>

                                                                            </div>
                                                                        </div> : ""}
                                                                        <form action="" className="bin__form">
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("wallet_page.amount")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="price-sell"
                                                                                        value={amountSell}
                                                                                        onChange={e => amountSellHandler(e)}

                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">

                                                                                        {match[1]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-input">
                                                                                <label className="form-input__label">
                                    <span className="form-input__placeholder">
                                      {t("right_menu.total")}
                                    </span>
                                                                                    <input
                                                                                        className="form-input__input"
                                                                                        id="amount-sell"
                                                                                        defaultValue=""
                                                                                        value={totalSell}
                                                                                        onChange={e => totalSellHandler(e)}


                                                                                    />
                                                                                    <span
                                                                                        className="form-input__currency">
                                      {match[2]}
                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                className="input-range-form red-icon"
                                                                                onChange={(ev) => amountSellHandler(ev)}
                                                                                type="range"
                                                                                step={0.00001}
                                                                                min="0"
                                                                                max={userBalanceSecond / AVGdata.lastPrice}
                                                                                defaultValue={0}
                                                                            />
                                                                            <div className={x ? "" : "tpsl"}>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.take_profit")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="price-sell"
                                                                                            defaultValue="0"

                                                                                            value={takeProfitShortMarket}
                                                                                            onChange={e => handleTakeProfitShortMarket(e)}
                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-input">
                                                                                    <label
                                                                                        className="form-input__label">
                                      <span className="form-input__placeholder">
                                        {t("order_details.stop_loss")}
                                      </span>
                                                                                        <input
                                                                                            className="form-input__input"
                                                                                            id="amount-sell"
                                                                                            defaultValue="0"
                                                                                            value={stopLossShortMarket}
                                                                                            onChange={e => handleStopLossShortMarket(e)}
                                                                                        />
                                                                                        <span
                                                                                            className="form-input__currency">
                                        {match[2]}
                                      </span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {isLoggedIn ? <button
                                                                                onClick={handleSubmitOrderShortMT}
                                                                                className="button">
                                                                                Продать/Шорт
                                                                            </button> : <NavLink to="/authorization"
                                                                                                 className="loginplease">{t("trade_buttons.log_in")}</NavLink>}
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bin__right">
                                    <div className="mil">
                                        <a href="">
                                            <img src="img/ski1.svg?_v=1689845484602" alt=""/>
                                        </a>
                                        <a href="">
                                            <img src="img/ski2.svg?_v=1689845484602" alt=""/>
                                        </a>
                                        <a href="">
                                            <img src="img/ski3.svg?_v=1689845484602" alt=""/>
                                        </a>
                                    </div>
                                    <div className="bin__cotirovki">
                                        <div className="bin__c-top">
                                            <div className="bin__c-top-item">
                                                <span>{t("right_menu.price")} ({match[2]})</span>
                                                <span>
                    <img src="img/cotir.svg?_v=1689845484602" alt=""/>
                  </span>
                                            </div>
                                            <div className="bin__c-top-item">
                                                <span>{t("right_menu.size")} ({match[1]})</span>
                                                <span>
                    <img src="img/cotir.svg?_v=1689845484602" alt=""/>
                  </span>
                                            </div>
                                            <div className="bin__c-top-item">
                                                <span>{t("right_menu.total")} ({match[2]})</span>
                                            </div>
                                        </div>
                                        <div className="bin__c-body">

                                            {isLoadedOrders ? orders.map(order => {
                                                    const [price, qty] = order;
                                                    return <Order setPropsPrice={setPropsPrice} stocks={true}
                                                                  setPropsPriceSell={setPropsPriceSell}
                                                                  text={"success-text"} price={price} qty={qty}/>
                                                }) :
                                                <>
                                                    <Loading/>
                                                    <Loading/>
                                                </>}
                                        </div>
                                    </div>
                                    <div className="bin__cotirovki twotwo">
                                        <div className="bin__c-top sitr">
                                            <div className="sitr__box">
                                                <span
                                                    className={`sitr__name ${AVGdata.priceChangePercent > 0 ? "success-text" : "text-error"}`}> {AVGdata.lastPrice} </span>
                                                <span>
                    {AVGdata.priceChangePercent > 0 ?
                        <svg
                            className="svgrtd"
                            xmlns="http://www.w3.org/2000/svg"
                            width={23}
                            height={22}
                            viewBox="0 0 23 22"
                            fill="none"
                        >
                            <path
                                d="M5.1922 12.5812L6.45082 11.3226L11.0033 15.8751V3.23529H12.7796V15.8661L17.3232 11.3226L18.5818 12.5812L11.887 19.276L5.1922 12.5812Z"
                                fill="#1a8754"
                            />
                        </svg>

                        :
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={23}
                            height={22}
                            viewBox="0 0 23 22"
                            fill="none"
                        >
                            <path
                                d="M5.1922 12.5812L6.45082 11.3226L11.0033 15.8751V3.23529H12.7796V15.8661L17.3232 11.3226L18.5818 12.5812L11.887 19.276L5.1922 12.5812Z"
                                fill="#f6465d"
                            />
                        </svg>

                    }
                  </span>
                                            </div>
                                            <span className="sitr__min"> {match[2]} </span>
                                        </div>
                                        <div className="bin__c-body">
                                            {isLoadedOrders ? ordersAsks.map(order => {
                                                    const [price, qty] = order;
                                                    return <Order setPropsPrice={setPropsPrice}
                                                                  setPropsPriceSell={setPropsPriceSell} stocks={true}
                                                                  asking={true} text={"text-danger"} price={price}
                                                                  qty={qty}/>
                                                }) :
                                                <>
                                                    <Loading/>
                                                    <Loading/>
                                                </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLoggedIn ? <div className="s__container">
                                <div className="bin__orders">
                                    <div data-tabs="" className="tabs">
                                        <nav data-tabs-titles="" className="tabs__navigation">
                                            <button onClick={() => setHistoryOrders(1)}
                                                    className={`tabs__title ${historyOrders == 1 ? "_tab-active" : ""}`}>
                                                {t("orders.open_orders")} ({userOrdersOpenTotal})
                                            </button>
                                            <button onClick={() => setHistoryOrders(2)}
                                                    className={`tabs__title ${historyOrders == 2 ? "_tab-active" : ""}`}>
                                                {t("orders.order_history")} ({userOrdersClosedTotal})
                                            </button>
                                            <button onClick={() => setHistoryOrders(3)}
                                                    className={`tabs__title ${historyOrders == 3 ? "_tab-active" : ""}`}>
                                                {t("orders.pending_orders")} ({userOrdersWaitingTotal})
                                            </button>
                                        </nav>


                                        <div data-tabs-body="" className="tabs__content">
                                            <div className={`tabs__body ${historyOrders == 1 ? "" : "hidden"}`}>
                                                <div className="bin__orders-body">
                                                    <div className="order-table__item">
                                                        <div
                                                            className="order-table__children-header">{t("order_details.symbol")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.entry_size")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.entry_price")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.margin")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.liquidation_price")}</div>
                                                        <div className="order-table__children-header">PNL (ROE%)</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.take_profit")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.stop_loss")}</div>
                                                        <div
                                                            className="bin__orders-name">{t("order_details.action")}</div>
                                                    </div>
                                                    <div className="bin__orders-body2 non">

                                                        {isLoadedOrdersTable ? elems : ""}

                                                    </div>
                                                </div>

                                                <div className="account__posts">
                                                    <button onClick={() => {
                                                        page1 === 1 ? setPage1(1) : newPageHandler(page1 - 1)
                                                    }} className="account__posts-btn">
                                                        <img src={posts1} alt=""/>
                                                    </button>
                                                    <div className="account__posts-nav">

                                                        {page1 === 1 ? "" : <button
                                                            onClick={() => newPageHandler(page1 - 1)}>{page1 - 1}</button>}
                                                        <button className="primary">
                                                            {page1}
                                                        </button>
                                                        <button
                                                            onClick={() => newPageHandler(page1 + 1)}>{page1 + 1}</button>
                                                        <button
                                                            onClick={() => newPageHandler(page1 + 2)}>{page1 + 2}</button>
                                                    </div>
                                                    <button onClick={() => newPageHandler(page1 + 1)}
                                                            className="account__posts-btn">
                                                        <img src={posts2} alt=""/>
                                                    </button>
                                                </div>

                                            </div>

                                            <div className={`tabs__body ${historyOrders == 2 ? "" : "hidden"}`}>
                                                <div className="bin__orders-body">
                                                    <div className="order-table__item">
                                                        <div className="order-table__children-header">Символ</div>
                                                        <div className="order-table__children-header">Размер</div>
                                                        <div className="order-table__children-header">Цена входа</div>
                                                        <div className="order-table__children-header">Маржа</div>
                                                        <div className="order-table__children-header">Цена ликвидации
                                                        </div>
                                                        <div className="order-table__children-header">PNL (ROE%)</div>
                                                        <div className="order-table__children-header">Тейк-профит</div>
                                                        <div className="order-table__children-header">Стоп-лосс</div>
                                                    </div>
                                                    <div className="bin__orders-body2 non">

                                                        {
                                                            isLoadedOrdersTable
                                                                ?
                                                                userOrdersClose.map(
                                                                    ordersTable => {
                                                                        axios.get()
                                                                        return (
                                                                            <OrdersTable
                                                                                size={ordersTable.margin * ordersTable.leverage}
                                                                                avg={AVGdata.lastPrice}
                                                                                date={ordersTable.time}
                                                                                isLong={ordersTable.short_long}
                                                                                price={ordersTable.price}
                                                                                coin={ordersTable.name}
                                                                                coinfrom={ordersTable.coin}
                                                                                amount={ordersTable.amount}
                                                                                filled={ordersTable.filled}
                                                                                total={ordersTable.total}
                                                                                action={ordersTable.action}
                                                                                id={ordersTable.id}
                                                                                chlen={true}
                                                                                marzha={ordersTable.margin}
                                                                                wasOpen={ordersTable.was_opened}
                                                                                pnl={ordersTable.pnl}
                                                                                pnlPercent={ordersTable.pnl_percent}
                                                                                leverage={ordersTable.leverage}
                                                                                takeProfit={ordersTable.take_profit}
                                                                                stopLoss={ordersTable.stop_loss}
                                                                                requireUserData={requireUserDataOpen}
                                                                                requireUserBalance={requireUserBalance}
                                                                            />)
                                                                    })
                                                                :
                                                                ""
                                                        }

                                                    </div>
                                                </div>
                                                <div className="account__posts">
                                                    <button onClick={() => {
                                                        page2 === 1 ? setPage2(1) : newPageHandler2(page2 - 1)
                                                    }} className="account__posts-btn">
                                                        <img src={posts1} alt=""/>
                                                    </button>
                                                    <div className="account__posts-nav">

                                                        {page2 === 1 ? "" : <button
                                                            onClick={() => newPageHandler2(page2 - 1)}>{page2 - 1}</button>}
                                                        <button className="primary">
                                                            {page2}
                                                        </button>
                                                        <button
                                                            onClick={() => newPageHandler2(page2 + 1)}>{page2 + 1}</button>
                                                        <button
                                                            onClick={() => newPageHandler2(page2 + 2)}>{page2 + 2}</button>
                                                    </div>
                                                    <button onClick={() => newPageHandler2(page2 + 1)}
                                                            className="account__posts-btn">
                                                        <img src={posts2} alt=""/>
                                                    </button>
                                                </div>

                                            </div>

                                            <div className={`tabs__body ${historyOrders == 3 ? "" : "hidden"}`}>
                                                <div className="bin__orders-body">
                                                    <div className="order-table__item">
                                                        <div
                                                            className="order-table__children-header">{t("order_details.symbol")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.entry_size")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.entry_price")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.margin")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.liquidation_price")}</div>
                                                        <div className="order-table__children-header">PNL (ROE%)</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.take_profit")}</div>
                                                        <div
                                                            className="order-table__children-header">{t("order_details.stop_loss")}</div>
                                                        <div
                                                            className="bin__orders-name">{t("order_details.action")}</div>
                                                    </div>
                                                    <div className="bin__orders-body2 non">

                                                        {
                                                            isLoadedOrdersTable
                                                                ?
                                                                userOrdersWaiting.map(
                                                                    ordersTable => {
                                                                        axios.get()
                                                                        return (
                                                                            <OrdersTable
                                                                                margin={ordersTable.margin}
                                                                                size={ordersTable.total * ordersTable.leverage}
                                                                                deleteButton={true}
                                                                                huy={true}
                                                                                avg={AVGdata.lastPrice}
                                                                                date={ordersTable.time}
                                                                                price={ordersTable.price}
                                                                                isLong={ordersTable.short_long}
                                                                                coin={ordersTable.name}
                                                                                coinfrom={ordersTable.coin}
                                                                                amount={ordersTable.amount}
                                                                                filled={ordersTable.filled}
                                                                                total={ordersTable.total}
                                                                                action={ordersTable.action}
                                                                                id={ordersTable.id}
                                                                                leverage={ordersTable.leverage}
                                                                                takeProfit={ordersTable.take_profit}
                                                                                stopLoss={ordersTable.stop_loss}
                                                                                requireUserData={requireUserDataOpen}
                                                                                requireUserBalance={requireUserBalance}
                                                                            />)
                                                                    })
                                                                :
                                                                ""
                                                        }

                                                    </div>
                                                </div>

                                                <div className="account__posts">
                                                    <button onClick={() => {
                                                        page3 === 1 ? setPage3(1) : newPageHandler3(page3 - 1)
                                                    }} className="account__posts-btn">
                                                        <img src={posts1} alt=""/>
                                                    </button>
                                                    <div className="account__posts-nav">

                                                        {page3 === 1 ? "" : <button
                                                            onClick={() => newPageHandler3(page3 - 1)}>{page3 - 1}</button>}
                                                        <button className="primary">
                                                            {page3}
                                                        </button>
                                                        <button
                                                            onClick={() => newPageHandler3(page3 + 1)}>{page3 + 1}</button>
                                                        <button
                                                            onClick={() => newPageHandler3(page3 + 2)}>{page3 + 2}</button>
                                                    </div>
                                                    <button onClick={() => newPageHandler3(page3 + 1)}
                                                            className="account__posts-btn">
                                                        <img src={posts2} alt=""/>
                                                    </button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div> : ""}
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
};


export default MarketStocks
