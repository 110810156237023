import React, {useState} from 'react';
import ss1 from "./img/ss1.svg";
import ss2 from "./img/ss2.svg";
import {NavLink} from "react-router-dom";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';

const Transaction = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const x = props.time

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear().toString().substr(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${year}.${month}.${day} ${hours}:${minutes}`;
    }
    const formattedString = formatDate(x);
    return (
            <div className="account__s-line">
                { props.isBankRefill && props.status == "waiting" ? <NavLink to={`../account/deposit?bankTransferId=${props.id}&amount=${props.value}`} className="account__s-name">
                    <img src={`https://api.dune-ex.com/api/${props.iclink.toLowerCase()}`} style={{width: 30}}
                         alt=""/>
                    <span>{props.card}</span>
                </NavLink> :
                    <div className="account__s-name">
                        <img src={`https://api.dune-ex.com/api/${props.iclink.toLowerCase()}`} style={{width: 30}}
                             alt=""/>
                        <span>{props.card}</span>
                    </div>}
                <div className="account__s-make">
                    <img src={ss2} alt="" />
                    <span>{props.action == 'refill' ? "Пополнение" : "Вывод" }</span>
                </div>
                <div className="account__s-id">
                    <span>{props.id}</span>
                </div>
                <div className="account__s-summ">
                    <span>{props.value}</span>
                </div>
                <div className="account__s-time">
                    <span>{formattedString}</span>
                </div>
                <div className="account__s-status">
                    {
                        props.status === "rejected" ?
                            <button aria-describedby={id} type="button" className="popper__button" onClick={handleClick}>
                                ?
                            </button>
                            : ""}
                    {props.status == "accepted" ? <span className="s-green">Успешно</span> : (props.status == "waiting" ? <span className="s-red">В процессе</span> :  <span className="s-red2">Отклонено </span>)}
                </div>

                <Popper id={id} open={open} anchorEl={anchorEl}>
                    <div className="popper__box">
                        причина отказа: {props.comment_rejected}
                    </div>
                </Popper>
            </div>
    );
};

export default Transaction;