import React from 'react';
import './comps.css'
import Bar from "./bar";
import img1 from '../../images/specials1.png'
import img2 from '../../images/specials2.png'
import EGOFUCKYOU from '../../images/EGOFUCKYOU.png'
import {useTranslation} from "react-i18next";
const Specials = () => {
    const {t, i18n} = useTranslation()
    let currentLanguage = i18n.language
    return (
        <div className="foot_container">

            <div className="comps__body">
                <Bar active={2}/>
                <div className="comps__content_new">

                    {currentLanguage === "ru" ? <h2 className="specials_sh1">Наша команда</h2> : ""}
                    {currentLanguage === "en" ? <h2 className="specials_sh1">Our Team</h2> : ""}
                    {currentLanguage === "de" ? <h2 className="specials_sh1">Unser Team</h2> : ""}

                    {currentLanguage === "ru" ? <div className="spec_flex_container">
                        <div className="spec_flex_col">

                            <h2 className="specials_sub1">
                                <div className="spec-dot"></div>
                                Группа научных исследований и разработок
                            </h2>
                            <p className="specials_text1">Наша команда состоит из специалистов c огромными техническими
                                возможностями, из ведущих технологических компаний, таких как Tencent, Alibaba и
                                Google.</p>
                            <h2 className="specials_sub1">
                                <div className="spec-dot"></div>
                                Инвестиционные исследовния в области управления рисками
                            </h2>
                            <p className="specials_text1">Команда ведущих финансовых экспертов, наши сотрудники имеют
                                большой опыт работы в сфере цифровой валюты и инвестиции.</p>
                        </div>
                    </div> : ""}
                    {currentLanguage === "en" ?
                        <div className="spec_flex_container">
                            <div className="spec_flex_col">
                                <h2 className="specials_sub1">
                                    <div className="spec-dot"></div>
                                    Research and Development Group
                                </h2>
                                <p className="specials_text1">Our team consists of specialists with extensive technical
                                    capabilities, from leading technology companies such as Tencent, Alibaba, and
                                    Google.</p>
                                <h2 className="specials_sub1">
                                    <div className="spec-dot"></div>
                                    Investment Research in Risk Management
                                </h2>
                                <p className="specials_text1">A team of leading financial experts, our employees have
                                    extensive experience in digital currency and investment.</p>
                            </div>
                        </div>
                        : ""}
                    {currentLanguage === "de" ? <div className="spec_flex_container">
                        <div className="spec_flex_col">
                            <h2 className="specials_sub1">
                                <div className="spec-dot"></div>
                                Forschungs- und Entwicklungsgruppe
                            </h2>
                            <p className="specials_text1">Unser Team besteht aus Spezialisten mit umfangreichen
                                technischen Fähigkeiten, von führenden Technologieunternehmen wie Tencent, Alibaba und
                                Google.</p>
                            <h2 className="specials_sub1">
                                <div className="spec-dot"></div>
                                Investitionsforschung im Risikomanagement
                            </h2>
                            <p className="specials_text1">Ein Team führender Finanzexperten, unsere Mitarbeiter haben
                                umfangreiche Erfahrung im Bereich digitaler Währungen und Investitionen.</p>
                        </div>
                    </div> : ""}





                    <div className="spec_command_card">
                        <img src={EGOFUCKYOU} alt="" className="card_img"/>
                        <h2 className="card_name">EGO</h2>
                        <h2 className="card_desc">Chief Executive Officer</h2>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Specials;