import React, { useEffect, useState } from 'react';
import revert from "../account/img/revert.svg";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import {useTranslation} from "react-i18next";

const Convertation = () => {
    const {t} = useTranslation()

    const token = localStorage.getItem('token');
    
    const [valueFrom, setValueFrom] = useState('1');
    const [coinFrom, setCoinFrom] = useState('btc');
    const [valueTo, setValueTo] = useState('');
    const [coinTo, setCoinTo] = useState('usdt');
    const [swapConvertEnabled, setSwapConvertEnabled] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [valueError, setValueError] = useState('');
    const [networkError, setNetworkError] = useState('');
    const [rates, setRates] = useState({});
    const [balanceFrom, setBalanceFrom] = useState()
    const [balanceTo, setBalanceTo] = useState()
    const [balance, setBalance] = useState("");

    const requireBalance = () => axios.get(
        `https://api.dune-ex.com/api/api/users/balance`,
        {
            headers: { "Authorization": `Bearer ${token}` }
        })
        .then(res => {
            setBalance(res.data);
            setBalanceFrom(Math.floor10(res.data.btc, -6))
            setBalanceTo(Math.floor10(res.data.usdt, -6))
        })

    useEffect(() => {
        setBalanceFrom(Math.floor10(balance[`${coinFrom}`], -6))
    }, [coinFrom])
    useEffect(() => {
        setBalanceTo(Math.floor10(balance[`${coinTo}`], -6))
    }, [coinTo])
    useEffect(() => {
        requireBalance()
    }, []);

    const fetchRate = async (symbol) => {
        if (symbol === "usdt" || symbol === "usd") return 1;
        const response = await axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol.toUpperCase()}USDT`);
        return parseFloat(response.data.lastPrice);
    };

    useEffect(() => {
        const updateRates = async () => {
            const rateFrom = await fetchRate(coinFrom);
            const rateTo = await fetchRate(coinTo);
            setRates({ [coinFrom]: rateFrom, [coinTo]: rateTo });
        };
        updateRates();
    }, [coinFrom, coinTo]);

    useEffect(() => {
        if (valueFrom && rates[coinFrom] && rates[coinTo]) {
            setValueTo((rates[coinFrom] / rates[coinTo] * valueFrom).toFixed(8));
        } else {
            setValueTo('');
        }
    }, [valueFrom, rates, coinFrom, coinTo]);

    useEffect(() => {
        setFormValid(!valueError && !networkError);
    }, [valueError, networkError]);

    const handleValueChange = (e) => {
        const newValue = e.target.value;
        setValueFrom(newValue);
        if (!newValue) {
            setValueError('Field cannot be empty!');
        } else {
            setValueError('');
        }
    };

    const handleCoinChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const swapConvert = async () => {
        setSwapConvertEnabled(true);
        await new Promise(resolve => setTimeout(resolve, 100)); // Small delay to ensure state updates
        setValueFrom(valueTo);
        setValueTo(valueFrom);
        setCoinFrom(coinTo);
        setCoinTo(coinFrom);
        setSwapConvertEnabled(false);
    };

    const handleSubmitWithdrawOrder = (event) => {
        event.preventDefault();
        axios({
            method: "post",
            url: "https://api.dune-ex.com/api/api/convert",
            data: { valueFrom, coinFrom, valueTo, coinTo, "balanceFrom": "main", "balanceTo": "main", "type": "main" },
            headers: { "Authorization": `Bearer ${token}` }
        }).then(res => {
            enqueueSnackbar(`Успешно!`, { variant: 'success', className: "snacksuccess" });
            console.log(res.data);
        });
    };

    return (
        <div className="wallet__item">
            <div className="wallet__title">{t("wallet_page.conversion")}</div>
            <div className="wallet__block">
                <div className="wallet__bom">
                    <p className="wallet__txt">{t("wallet_page.available")}: {balanceFrom} {coinFrom.toUpperCase()}</p>
                    <div className="wallet__bomm">
                        <div className="wallet__bomm-left">
                            <p className="wallet__txt2">{t("wallet_page.amount")}</p>
                            <input
                                type="text"
                                className="wallet__value"
                                value={valueFrom}
                                onChange={handleValueChange}
                            />
                        </div>
                        <div className="wallet__right">
                            <select className="select" value={coinFrom} onChange={handleCoinChange(setCoinFrom)}>
                                {['btc', 'ada', 'dot', 'eth', 'sol', 'trx', 'uni', 'usd', 'usdt', 'matic', 'link', 'bnb', 'xrp', 'doge', 'bch', 'ltc'].map(coin => (
                                    <option key={coin} value={coin}>{coin.toUpperCase()}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <button className="revert" disabled={swapConvertEnabled} onClick={swapConvert}>
                    <img src={revert} alt="" />
                </button>
                <div className="wallet__bom bom2">
                    <p className="wallet__txt">{t("wallet_page.available")}: {balanceTo} {coinTo.toUpperCase()}</p>
                    <div className="wallet__bomm">
                        <div className="wallet__bomm-left">
                            <p className="wallet__txt2">{t("wallet_page.transfer_amount")}</p>
                            <input
                                type="text"
                                className="wallet__value"
                                value={valueTo}
                                disabled
                            />
                        </div>
                        <div className="wallet__right">
                            <select className="select" value={coinTo} onChange={handleCoinChange(setCoinTo)}>
                                {['btc', 'ada', 'dot', 'eth', 'sol', 'trx', 'uni', 'usd', 'usdt', 'matic', 'link', 'bnb', 'xrp', 'doge', 'bch', 'ltc'].map(coin => (
                                    <option key={coin} value={coin}>{coin.toUpperCase()}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" className="button" disabled={!formValid} onClick={handleSubmitWithdrawOrder}>
                Обмен
            </button>
        </div>
    );
};

export default Convertation;