import React from 'react';
import './footer.css'
import {NavLink} from "react-router-dom";
import logo from '../../images/logo.svg'
import {useTranslation} from "react-i18next";
const Footer = () => {
    const {t} = useTranslation()
    return (
        <footer className="footer-m">
            <div className="footer-container">
                <div className="footer-section">
                    <NavLink to="/main"><img src={logo} alt=""/></NavLink>
                    <div className="rights">DuneEX © 2021-2024</div>
                </div>

                <div className="footer-section">
                    <NavLink to='/about' className="footer-link">{t("footer.about_us")}</NavLink>
                    <NavLink to='/specialists' className="footer-link">{t("footer.analyst_team")}</NavLink>
                </div>

                <div className="footer-section">

                    <NavLink to='/privacy' className="footer-link">{t("footer.privacy_policy")}</NavLink>
                    <NavLink to='/contacts' className="footer-link">{t("footer.contacts")}</NavLink>
                </div>
            </div>
        </footer>
    );
};

export default Footer;