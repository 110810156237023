import React, {useEffect} from 'react';
import './comps.css'
import Bar from "./bar";
import img1 from "../../images/specials1.png";
import {useTranslation} from "react-i18next";
const About = () => {
    const {t, i18n} = useTranslation()
    let currentLanguage = i18n.language
    return (
        <div className="foot_container">
            <div className="comps__body">
                <Bar active={1}/>
                {currentLanguage === "ru" ? <div className="comps__content">

                    <p>
                        <span className="footer-title-comp" style={{color: "#FFFFFF"}}>О нас</span>
                    </p>
                    <p><strong>DuneEX</strong> &mdash; это глобальная криптовалютная биржа, предоставляющая платформу
                        для торговли различными криптовалютами. Она была основана в 2021 году и зарегистрирована на
                        Сейшельских островах. Группа работает в России, Бразилии, Вьетнаме, Сейшельских островах,
                        Сингапуре, Японии и Индонезии, занимаясь исследованиями и разработками в области маркетинга,
                        инвестиций и технологий блокчейна.</p>
                    <p>&nbsp;</p>
                    <p>Миссия <strong>DuneEX</strong> &mdash; предоставить всем гражданам в эпоху цифровых технологий
                        наиболее безопасные и удобные услуги торговли и опеки. Из-за недавних лазеек в рыночной
                        безопасности и нестабильности политики мы считаем, что граждане в эпоху цифровых технологий
                        заслуживают лучшего выбора, поскольку им нужна действительно безопасная, удобная и заслуживающая
                        доверия инфраструктура. <strong>DuneEX</strong> помогает пользователям находить более ценные
                        зашифрованные активы, обеспечивать высококачественный торговый опыт и гарантировать, что клиенты
                        могут безопасно и удобно торговать и управлять своими цифровыми активами с помощью наших
                        современных технологий и процессов управления.</p>
                    <p>&nbsp;</p>
                    <p>Что отличает <strong>DuneEX</strong>?</p>
                    <p>1. Чрезвычайно безопасный</p>
                    <p><strong>DuneEX</strong> использует двухэтапную проверку и уведомление;</p>
                    <p><strong>DuneEX</strong> разделяет холодные и горячие кошельки, развертывает несколько подписей и
                        отслеживает состояние кошельков и активов в режиме реального времени. Никто не может получить
                        закрытый ключ самостоятельно из-за способа создания зашифрованного ключа.</p>
                    <p><strong>DuneEX</strong> стремится стать самой безопасной в мире платформой для торговли активами
                        блокчейна, используя глубоко укоренившийся технологический опыт и операционный процесс,
                        значительно снижая риски и гарантируя безопасность цифровых активов наших пользователей.</p>
                    <p>&nbsp;</p>
                    <p>2. Универсальное обслуживание</p>
                    <p>В настоящее время <strong>DuneEX</strong> имеет полную линейку продуктов, таких как спотовые
                        пулы, кредитное плечо, фьючерсы, управление активами. В рамках удовлетворения разнообразных
                        потребностей пользователей в инвестиционных активах <strong>DuneEX</strong> внимательно
                        отслеживает движение рынка, что помогает пользователям участвовать в тенденциях, что поможет им
                        обеспечить свои преимущества.</p>
                    <p>&nbsp;</p>
                    <p>3. Доверительное управление несколькими активами</p>
                    <p><strong>DuneEX</strong> поддерживает несколько активов блокчейна, включая BTC, ETH, ERC20, ETC,
                        ZEC, SC. <strong>DuneEX</strong> стремится удовлетворить все требования пользователей.</p>
                    <p>&nbsp;</p>
                    <p>4. Бесперебойный торговый опыт</p>
                    <p><strong>DuneEX</strong> использует передовые технологии для обеспечения скорости транзакций,
                        обновляет подробную книгу заказов и прибыль/убыток, чтобы пользователи могли получить лучший
                        торговый опыт, который у них когда-либо был.</p>
                    <p>&nbsp;</p>
                    <p>5. Поддержка нескольких платформ</p>
                    <p><strong>DuneEX</strong> &mdash; это веб-платформа, поддерживающая все операционные системы и
                        устройства.</p>
                    <p>&nbsp;</p>
                    <p>6. Подтверждение резервов (100% резервов активов)</p>
                    <p><strong>DuneEX</strong> хранит активы пользователей в резервах и не будет использовать эти активы
                        каким-либо образом. Механизм, предоставляемый <strong>DuneEX</strong>, позволяет пользователям
                        отслеживать состояние активов в любое время.</p>
                </div> : ""}


                {currentLanguage === "en" ? <div className="comps__content">

                        <p>
                            <span className="footer-title-comp" style={{color: "#FFFFFF"}}>About Us</span>
                        </p>
                        <p><strong>DuneEX</strong> is a global cryptocurrency exchange that provides a platform for trading
                            various cryptocurrencies. Founded in 2021 and registered in the Seychelles, the group operates
                            in Russia, Brazil, Vietnam, the Seychelles, Singapore, Japan, and Indonesia, engaging in
                            research and development in marketing, investments, and blockchain technologies.</p>
                        <p>&nbsp;</p>
                        <p>The mission of <strong>DuneEX</strong> is to provide all citizens in the digital technology era
                            with the safest and most convenient trading and custody services. Due to recent gaps in market
                            security and political instability, we believe citizens in the digital technology era deserve
                            better choices, as they need truly secure, convenient, and trustworthy
                            infrastructure. <strong>DuneEX</strong> helps users find more valuable encrypted assets, ensures
                            a high-quality trading experience, and guarantees that clients can safely and conveniently trade
                            and manage their digital assets using our advanced technologies and management processes.</p>
                        <p>&nbsp;</p>
                        <p>What sets <strong>DuneEX</strong> apart?</p>
                        <p>1. Extremely Secure</p>
                        <p><strong>DuneEX</strong> employs two-step verification and notification;</p>
                        <p><strong>DuneEX</strong> separates cold and hot wallets, deploys multiple signatures, and tracks
                            wallet and asset status in real-time. No one can independently obtain the private key due to the
                            way encrypted keys are created.</p>
                        <p><strong>DuneEX</strong> aims to become the world's safest blockchain asset trading platform,
                            using deeply rooted technological expertise and operational processes, significantly reducing
                            risks and ensuring the security of our users' digital assets.</p>
                        <p>&nbsp;</p>
                        <p>2. Comprehensive Service</p>
                        <p>Currently, <strong>DuneEX</strong> offers a full range of products such as spot pools, credit
                            leverage, futures, and asset management. To meet diverse user needs in investment
                            assets, <strong>DuneEX</strong> closely monitors market movements, helping users participate in
                            trends to secure their advantages.</p>
                        <p>&nbsp;</p>
                        <p>3. Trustworthy Multiple Assets Management</p>
                        <p><strong>DuneEX</strong> supports multiple blockchain assets, including BTC, ETH, ERC20, ETC, ZEC,
                            SC. <strong>DuneEX</strong> aims to satisfy all user requirements.</p>
                        <p>&nbsp;</p>
                        <p>4. Seamless Trading Experience</p>
                        <p><strong>DuneEX</strong> uses advanced technologies to ensure transaction speed, updates detailed
                            order books and profit/loss, allowing users to have the best trading experience they've ever
                            had.</p>
                        <p>&nbsp;</p>
                        <p>5. Multi-platform Support</p>
                        <p><strong>DuneEX</strong> is a web platform supporting all operating systems and devices.</p>
                        <p>&nbsp;</p>
                        <p>6. Proof of Reserves (100% Asset Reserves)</p>
                        <p><strong>DuneEX</strong> stores user assets in reserves and will not use these assets in any way.
                            The mechanism provided by <strong>DuneEX</strong> allows users to track asset status at any
                            time.</p>
                    </div> : ""}


                {currentLanguage === "de" ? <div className="comps__content">

                        <p>
                            <span className="footer-title-comp" style={{color: "#FFFFFF"}}>Über uns</span>
                        </p>
                        <p><strong>DuneEX</strong> ist eine globale Kryptowährungsbörse, die eine Plattform für den Handel
                            mit verschiedenen Kryptowährungen bietet. Gegründet im Jahr 2021 und registriert auf den
                            Seychellen, operiert die Gruppe in Russland, Brasilien, Vietnam, den Seychellen, Singapur, Japan
                            und Indonesien und widmet sich der Forschung und Entwicklung in den Bereichen Marketing,
                            Investitionen und Blockchain-Technologien.</p>
                        <p>&nbsp;</p>
                        <p>Die Mission von <strong>DuneEX</strong> ist es, allen Bürgern im Zeitalter digitaler Technologien
                            die sichersten und bequemsten Handels- und Verwaltungsdienste anzubieten. Aufgrund jüngster
                            Sicherheitslücken im Markt und politischer Instabilität sind wir der Ansicht, dass Bürger im
                            Zeitalter digitaler Technologien bessere Optionen verdienen, da sie eine wirklich sichere,
                            bequeme und vertrauenswürdige Infrastruktur benötigen. <strong>DuneEX</strong> hilft Benutzern,
                            wertvollere verschlüsselte Vermögenswerte zu finden, bietet ein hochwertiges Handelserlebnis und
                            gewährleistet, dass Kunden ihre digitalen Vermögenswerte sicher und bequem handeln und verwalten
                            können, indem sie unsere fortschrittlichen Technologien und Managementprozesse nutzen.</p>
                        <p>&nbsp;</p>
                        <p>Was zeichnet <strong>DuneEX</strong> aus?</p>
                        <p>1. Extrem sicher</p>
                        <p><strong>DuneEX</strong> verwendet eine Zwei-Faktor-Authentifizierung und Benachrichtigungen;</p>
                        <p><strong>DuneEX</strong> trennt kalte und heiße Geldbörsen, setzt mehrere Signaturen ein und
                            überwacht den Status von Geldbörsen und Vermögenswerten in Echtzeit. Niemand kann den privaten
                            Schlüssel unabhängig erhalten, aufgrund der Art und Weise, wie verschlüsselte Schlüssel erstellt
                            werden.</p>
                        <p><strong>DuneEX</strong> strebt danach, die sicherste Handelsplattform für
                            Blockchain-Vermögenswerte der Welt zu werden, indem es tief verwurzeltes technologisches
                            Know-how und betriebliche Prozesse nutzt, um Risiken signifikant zu reduzieren und die
                            Sicherheit der digitalen Vermögenswerte unserer Benutzer zu gewährleisten.</p>
                        <p>&nbsp;</p>
                        <p>2. Umfassender Service</p>
                        <p>Derzeit bietet <strong>DuneEX</strong> eine vollständige Palette von Produkten wie Spotpools,
                            Kredithebel, Futures und Vermögensverwaltung an. Um den vielfältigen Nutzerbedürfnissen bei
                            Investitionsvermögenswerten gerecht zu werden, überwacht <strong>DuneEX</strong> die
                            Marktbewegungen genau, um den Nutzern zu helfen, Trends zu nutzen und ihre Vorteile zu sichern.
                        </p>
                        <p>&nbsp;</p>
                        <p>3. Vertrauenswürdige Verwaltung mehrerer Vermögenswerte</p>
                        <p><strong>DuneEX</strong> unterstützt mehrere Blockchain-Vermögenswerte, darunter BTC, ETH, ERC20,
                            ETC, ZEC und SC. <strong>DuneEX</strong> zielt darauf ab, alle Benutzeranforderungen zu
                            erfüllen.</p>
                        <p>&nbsp;</p>
                        <p>4. Nahtloses Handelserlebnis</p>
                        <p><strong>DuneEX</strong> verwendet fortschrittliche Technologien, um die
                            Transaktionsgeschwindigkeit sicherzustellen, aktualisiert detaillierte Auftragsbücher sowie
                            Gewinn/Verlust, sodass Benutzer das beste Handelserlebnis haben können, das sie je hatten.</p>
                        <p>&nbsp;</p>
                        <p>5. Unterstützung für mehrere Plattformen</p>
                        <p><strong>DuneEX</strong> ist eine Webplattform, die alle Betriebssysteme und Geräte unterstützt.
                        </p>
                        <p>&nbsp;</p>
                        <p>6. Bestätigung der Reserven (100% Reserven der Vermögenswerte)</p>
                        <p><strong>DuneEX</strong> speichert Benutzervermögenswerte in Reserven und wird diese
                            Vermögenswerte nicht in irgendeiner Weise verwenden. Der
                            von <strong>DuneEX</strong> bereitgestellte Mechanismus ermöglicht es Benutzern, den Status
                            ihrer Vermögenswerte jederzeit nachzuverfolgen.</p>
                    </div>
                    : ""}

            </div>
        </div>
    );
};

export default About;