import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Импортируйте ваши переводы
import enTranslation from './locales/en.json';
import ruTranslation from './locales/ru.json';
import deTranslation from './locales/de.json'; // Добавьте эту строку

const selectedLanguage = localStorage.getItem("selected_lang")
let navLang = navigator.language == "ru"||"ru-RU" ? "ru" : navigator.language == "en"||"en-EN" ? "en" : navigator.language == "de"||"de-DE" ? "de" : "en"
const resources = {
    en: {
        translation: enTranslation
    },
    ru: {
        translation: ruTranslation
    },
    de: {
        translation: deTranslation // Добавьте эту строку
    }
};

i18n
    .use(initReactI18next) // Передаем i18n ребёнку реакта
    .init({
        resources,
        lng: selectedLanguage||navLang, // Язык по умолчанию
        interpolation: {
            escapeValue: false // React уже защищает от XSS
        }
    });

export default i18n;